
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import AtomBadge, { BadgeVariant } from '@/components/atoms/AtomBadge.vue';
import AtomCircularButton from '@/components/atoms/AtomCircularButton.vue';
import AtomCircularIcon, {
  CircularIconVariant,
  CircularIconSize,
} from '@/components/atoms/AtomCircularIcon.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import MoleculeCard, { CardVariant } from '@/components/molecules/cards/MoleculeCard.vue';
import MoleculeDropdown from '@/components/molecules/dropdown/MoleculeDropdown.vue';

export default Vue.extend({
  name: 'MoleculeCarCard',
  components: {
    AtomBadge,
    AtomCircularButton,
    AtomCircularIcon,
    AtomSvgIcon,
    MoleculeCard,
    MoleculeDropdown,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    seats: {
      type: Number,
      required: true,
    },
    licensePlate: {
      type: String,
      required: true,
    },
    fuel: {
      type: String,
      default: '',
    },
    imageURL: {
      type: String,
      default: '',
    },
    isDefault: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    BadgeVariant,
    CardVariant,
    CircularIconVariant,
    CircularIconSize,
  }),
  computed: {
    ...mapGetters('car', ['getCarById']),
  },
  methods: {
    ...mapActions('createCar', ['loadCar']),
    ...mapActions('car', ['deleteCarById', 'fetchAllCars']),
    editCar() {
      this.loadCar(this.getCarById(this.id));
      this.$bvModal.show('tg-modal--add-car');
    },
    async deleteCar() {
      this.$emit('delete', this.id);
    },
    // This function doesn't work when it's in computed section -> TS Bug
    getFuel() {
      switch (this.fuel) {
        case 'gasoline':
          return {
            name: this.$t('dictionary.fuelType.petrol'),
            icon: 'icons/icon-petrol-station-fuel.svg',
          };
        case 'diesel':
          return {
            name: this.$t('dictionary.fuelType.diesel'),
            icon: 'icons/icon-petrol-station-fuel.svg',
          };
        case 'electric':
          return {
            name: this.$t('dictionary.fuelType.electric'),
            icon: 'icons/icon-petrol-station-electric.svg',
          };
        case 'hybrid':
          return {
            name: this.$t('dictionary.fuelType.hybrid'),
            icon: 'icons/icon-petrol-station-electric.svg',
          };
        default:
          return { name: this.$t('dictionary.fuelType.other'), icon: 'icons/icon-petrol-station-fuel.svg' };
      }
    },
  },
});
