
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import AtomCarCardPlaceholder from '@/components/atoms/AtomCarCardPlaceholder.vue';
import OrganismPageSubHeader from '@/components/organisms/page-header/OrganismPageSubHeader.vue';
import OrganismCreateCar from '@/components/organisms/car/OrganismAddCar.vue';
import MoleculeCarCard from '@/components/molecules/car/MoleculeCarCard.vue';
import MoleculeConfirmModal, { MoleculeConfirmModalRef } from '@/components/molecules/modal/MoleculeConfirmModal.vue';
import { toast, ToastVariants } from '../../atoms/AtomToast';

export default Vue.extend({
  name: 'MyCars',
  components: {
    AtomButton,
    AtomCarCardPlaceholder,
    OrganismPageSubHeader,
    OrganismCreateCar,
    MoleculeCarCard,
    MoleculeConfirmModal,
  },
  data: () => ({
    ButtonVariant,
  }),
  computed: {
    ...mapGetters('car', ['getAllCars']),
    ...mapGetters('user', ['getUserId']),
    confirmRef(): MoleculeConfirmModalRef {
      return this.$refs.confirmationModal as MoleculeConfirmModalRef;
    },
    warningText(): string {
      return this.getAllCars.length > 1
        ? this.$t('myCars.warning.description').toString()
        : this.$t('myCars.warning.descriptionLastCar').toString();
    },
  },
  methods: {
    ...mapActions('car', ['fetchAllCars', 'deleteCarById']),
    async deleteCar(id: string) {
      const ok = await this.confirmRef.open();
      if (ok) {
        this.deleteCarById(id)
          .then(() => {
            toast(this.$bvToast, this.$t('myCars.deleteCarSuccess').toString());
            this.fetchAllCars();
          })
          .catch(() => {
            toast(this.$bvToast, this.$t('myCars.deleteCarSuccess').toString(), ToastVariants.DANGER);
          });
      }
    },
  },
  mounted() {
    if (this.getAllCars.length === 0) {
      this.fetchAllCars(this.getUserId);
    }
  },
});
