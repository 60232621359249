
import Vue from 'vue';
import i18n from '@/i18n';
import TemplateMyCars from '@/components/templates/cars/TemplateMyCars.vue';

export default Vue.extend({
  name: 'MyCars',
  components: { TemplateMyCars },
  metaInfo: {
    title: ` - ${i18n.t('tabs.cars')}`,
  },
});
